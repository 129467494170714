<template>
	<div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
		<div class="container">
			<p class="logo"><a href="/"><img src="/static/img/flowe.png" class="imglogo"></a></p>
			<div>
				<div class="pagetitle2">
					<h2 class="text-center">Oops! No access.</h2>
					<p class="text-center">The account you are currently signed in with does not have access to the page you have requested. To access the page you will need to request and get access, or sign out and then sign in with an account that has access.</p>
				</div>
				<div class="form-group responsepanel text-center">
					<a href="/?redirect=organizations" class="btn bt-xs btn-primary">Keep me signed in</a> <a href="/sign-out" class="btn bt-xs btn-secondary-light">Sign out</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'405error',

		mounted() { 
			$('#preloader').addClass('hide')
		}


	}
</script>